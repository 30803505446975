const getters = {
  isLogin: (state) => state.login.isLogin,
  userId: (state) => state.user.userId,
  orgId: (state) => state.user.orgId,
  orgName: (state) => state.user.orgName,
  openId: (state) => state.wx.openId,
  headimgurl: (state) => state.wx.headimgurl,
  nickname: (state) => state.wx.nickname,
  globalAuthorities: (state) => state.user.globalAuthorities,
  routesMap: (state) => state.user.routesMap,
  employeeId: (state) => state.user.employeeId,
  employeeName: (state) => state.user.employeeName,
  employeePhone: (state) => state.user.employeePhone,
  isFromV2: (state) => state.equipment.isFromV2,
  equipmentId: (state) => state.equipment.equipmentId,
  equipmentName: (state) => state.equipment.equipmentName,
  equipmentManufacturerName: (state) =>
    state.equipment.equipmentManufacturerName,
  equipmentModelName: (state) => state.equipment.equipmentModelName,
  equipmentSn: (state) => state.equipment.equipmentSn,
  equipmentNo: (state) => state.equipment.equipmentNo,
  equipmentDepartmentId: (state) => state.equipment.equipmentDepartmentId,
  equipmentDepartmentName: (state) => state.equipment.equipmentDepartmentName,
};

export default getters;
