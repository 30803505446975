import request from '@/utils/request';

/**
 * 获取设备详情
 * @param {*} id
 * @returns
 */
export async function fetchEquipmentDetail(id) {
  return request(`/v3/equipment/getInfo/${id}`);
}

/**
 * 获取设备维保历史
 * @param {*} id
 * @param {*} data
 * @returns
 */
export async function fetchEquipmentTimeline(id, data = {}) {
  return request({
    url: `/v3/equipment/timeline/list/${id}`,
    method: 'POST',
    data,
  });
}

export async function fetchEquipmentWarranthyStatus() {
  return request('/v3/equipment/enum/warranthyStatus');
}

/// 设备[维保状态]
export async function fetchEquipmentStatus() {
  return request('/v3/equipment/enum/equipmentStatus');
}

// 查询设备来源
export async function fetchEquipmentSourceType() {
  return request('/v3/equipment/enum/sourceType');
}

// 根据设备id获取签约公司
export async function fetchMaintainersByEquipmentId(equipmentId) {
  return request(`/v3/cr/org/${equipmentId}`);
}

// 根据老版系统的设备id来查询设备详情
export async function fetchEquipmentDetailFromQrCode(equipmentId) {
  return request(`/v3/equipment/getInfoByQrCode?equipmentNo=${equipmentId}`);
}

// 判断是否有查看设备的权限
export async function checkIsCanReadEquipment(equipmentId, isAcl) {
  const url = `/v3/equipment/${
    isAcl ? 'view/acl/' : ''
  }predicate?equipmentId=${equipmentId}`;
  return request(url);
}
