import Vue from 'vue';
import router from '@/router';
import store from '@/store';
import App from './App.vue';
import 'lib-flexible/flexible';
import VConsole from 'vconsole';
import installComponent from '@/utils/installComponent';
import './permission.js';

installComponent();

if (process.env.VUE_APP_MODE === 'dev') {
  // eslint-disable-next-line no-new
  new VConsole();
}

new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
});
