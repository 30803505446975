import request from '@/utils/request';

/**
 * 登录接口
 * @param {*} data
 * @returns
 */
export async function login(data) {
  return request({
    url: '/ent/login',
    method: 'POST',
    data,
  });
}

/**
 * 查询微信绑定的账户
 * @param {*} id
 * @returns
 */
export async function fetchAccount(openId) {
  return request({
    url: '/ent/account/queryWxBindAccount',
    params: {
      wxId: openId,
    },
  });
}

/**
 * 绑定微信
 * @param {*} uid
 * @param {*} openId
 * @returns
 */
export async function bindWechat(uid, openId) {
  return request({
    url: '/v3/account/bindWx',
    method: 'POST',
    data: {
      uid,
      wxId: openId,
    },
  });
}
