import { Toast } from 'vant';

const actions = {
  showLoading({ commit }, message = '加载中') {
    Toast.allowMultiple();
    const instance = Toast.loading({
      duration: 0,
      forbidClick: true,
      message,
    });
    commit('SET_INSTANCE', instance);
  },
  hideLoading({ commit, state }) {
    if (state.toastInstance) {
      state.toastInstance.clear();
      commit('SET_INSTANCE', null);
    }
  },
};

const mutations = {
  SET_INSTANCE(state, instance) {
    state.toastInstance = instance;
  },
};

export default {
  state() {
    return {
      toastInstance: null,
    };
  },
  actions,
  mutations,
};
