<template>
  <router-view />
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="less">
@import '@/styles/mixins.less';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f1f3;
}

* {
  box-sizing: border-box;
  .unselectable();
}

#__vconsole {
  .unselectable();
}
</style>
