const mutations = {
  SET_LOGIN_STATUS(state, isLogin) {
    state.isLogin = isLogin;
  },
};

export default {
  state() {
    return {
      isLogin: false,
    };
  },
  mutations,
};
