<template>
  <svg class="icon">
    <use :href="`#${name}`"></use>
  </svg>
</template>

<script>
  export default {
    name: 'iconfont',
    props: {
      name: String,
    },
  }
</script>

<style>
  .icon {
    width: 100%;
    height: 100%;
    overflow: hidden;
    vertical-align: -0.15em;
    fill: currentColor;
  }
</style>
