import qs from 'qs';
import { fetchEquipmentDetailFromQrCode } from '@/services/equipment';

export const formatMoney = (
  number,
  decimals = 0,
  decPoint = '.',
  thousandsSep = ',',
) => {
  number = (number + '').replace(/[^0-9+-Ee.]/g, '');
  const n = !isFinite(+number) ? 0 : +number;
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep;
  const dec = typeof decPoint === 'undefined' ? '.' : decPoint;
  let s = '';
  const toFixedFix = function (n, prec) {
    const k = Math.pow(10, prec);
    return '' + Math.ceil(n * k) / k;
  };
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  const re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2');
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
};

// 乘法函数，用来得到精确的乘法结果
export const accMul = (arg1, arg2) => {
  let m = 0;
  const s1 = arg1.toString();
  const s2 = arg2.toString();
  try {
    if (s1.includes('.')) {
      m += s1.split('.')[1].length;
    }
  } catch (e) {
    console.error(e);
  }
  try {
    if (s2.includes('.')) {
      m += s2.split('.')[1].length;
    }
  } catch (e) {
    console.error(e);
  }
  return (
    (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
    // eslint-disable-next-line no-restricted-properties
    Math.pow(10, m)
  );
};

export const getAudioDuration = (url) => {
  return new Promise((resolve, reject) => {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'arraybuffer';
    request.onload = function () {
      audioContext.decodeAudioData(request.response, function (buffer) {
        return resolve(Math.ceil(buffer.duration));
      });
    };
    request.onerror = function (error) {
      return reject(error);
    };
    request.send();
  });
};

export const getQuery = () => {
  const { search } = location;
  const queryString = search ? search.replace('?', '') : '';
  return qs.parse(queryString);
};

export const getEquipmentId = async (rawEquipmentId) => {
  try {
    const { code, data } = await fetchEquipmentDetailFromQrCode(rawEquipmentId);
    if (code === 0) {
      if (data !== null && Array.isArray(data) && data.length) {
        return data[0].equipment.id;
      }
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const extractRawEquipmentId = () => {
  const { pathname } = location;
  const isFromV2 = isFromVersion2();
  let rawEquipmentId;
  if (isFromV2) {
    const matched = pathname.match(/^\/Center\/scanRelease\/(\d+)\/\d+\/\d+$/);
    if (matched && matched.length === 2) {
      rawEquipmentId = matched[1];
    }
  } else {
    const matched = pathname.match(/^\/equipment\/(\d+)$/);
    if (matched && matched.length === 2) {
      rawEquipmentId = matched[1];
    }
  }
  return rawEquipmentId;
};

export const isFromVersion2 = () => {
  const { pathname } = location;
  return pathname.includes('/Center/scanRelease');
};

// 复制文字到粘贴板
export const copy2Clipboard = (value) => {
  const input = document.createElement('input');
  input.setAttribute('readonly', 'readonly');
  input.setAttribute('value', value);
  document.body.appendChild(input);
  input.setSelectionRange(0, 9999);
  if (document.execCommand('copy')) {
    input.select();
    document.execCommand('copy');
  }
  document.body.removeChild(input);
};

/**
 * 检查用户是否同意授权过录音权限
 * 背景：用户在第一次进行录音时，微信可能（注意是可能）会弹出是否同意授权录音权限的模态框，但这时已经调用了开始录音的逻辑，导致没办法
 * 停止录音，所以在这里做个缓存判断，如果用户没有进行授权过，那进入到这个页面就立刻调用一次录音接口，强制出现授权弹框。
 */
export const checkIsAllowRecord = () => {
  const isAllowRecord = localStorage.getItem('isAllowRecord');
  if (!isAllowRecord) {
    const successCb = () => {
      console.log('唤起授权：停止录音成功');
    };
    const failCb = (e) => {
      console.log('唤起授权：停止录音失败');
      console.log(e);
    };
    window.wx.startRecord({
      success: () => {
        console.log('自动检测用户是否同意授权过录音权限开始');
        localStorage.setItem('isAllowRecord', '1');
        window.wx.stopRecord({
          success: successCb,
          fail: failCb,
        });
      },
      cancel: () => {
        console.log('用户不同意授权录音权限');
        window.wx.stopRecord();
      },
      fail: (err) => {
        console.log('检查用户是否同意授权过录音权限：开始录音失败');
        console.log(err);
        window.wx.stopRecord();
      },
    });
  }
};

export const getPageTitle = (pageTitle = '云智臻') => {
  return pageTitle;
};

export const isIOS = () => {
  const UA = navigator.userAgent;

  return !!UA.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
};

export const isAndroid = () => {
  const UA = navigator.userAgent;
  return UA.indexOf('Android') > -1 || UA.indexOf('Adr') > -1;
};

export const isEquipmentPage = () => {
  const { pathname } = location;

  if (
    pathname.includes('/Center/scanRelease') ||
    pathname.includes('/equipment')
  ) {
    return true;
  }

  return false;
};

export const formatDate = (date, format = 'yyyy-MM-dd hh:mm:ss') => {
  var o = {
    'y+': date.getFullYear(),
    'M+': date.getMonth() + 1, // 月份 "d+": value.getDate(), // 日
    'd+': date.getDate(),
    'h+': date.getHours(), // 小时 "m+": value.getMinutes(), // 分 "s+": value.getSeconds(), // 秒
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
  };
  if (/(y+)/.test(format)) {
    format = format.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length),
    );
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length),
      );
    }
  }
  return format;
};
