import request from '@/utils/request';

/**
 * 获取微信用户信息
 * @param {*} code
 * @returns
 */
export async function fetchWechatUserInfo(code) {
  return request(`/v3/account/getUserInfoByCode?code=${code}`);
}

/**
 * 获取微信临时票据jsapi_ticket
 * @param {*} url
 * @returns
 */
export async function fetchWechatJsApiTicket(url) {
  return request(`/v3/account/getJsApiTicket?url=${url}`);
}

/**
 * 上传图片到微信服务器
 * @param {*} localId
 * @returns
 */
export async function uploadImage2Wechat(localIds) {
  const uploadData = [];
  const upload = (localId) => {
    return new Promise((resolve, reject) => {
      window.wx.uploadImage({
        localId,
        isShowProgressTips: 1,
        success: (res) => {
          resolve({
            serverId: res.serverId,
            localId: localId,
          });
        },
        fail: (error) => {
          console.error('上传图片至微信服务器失败（uploadImage）');
          reject(error);
        },
      });
    });
  };

  for (let i = 0; i < localIds.length; i++) {
    uploadData.push(await upload(localIds[i]));
  }

  return uploadData;
}

/**
 * 上传音频到微信服务器
 * 上传语音有效期3天，可用微信多媒体接口下载语音到自己的服务器，此处获得的 serverId 即 media_id
 * @param {*} localId
 * @returns
 */
export function uploadAudio2Wechat(localId) {
  return new Promise((resolve, reject) => {
    window.wx.uploadVoice({
      localId,
      isShowProgressTips: 1,
      success: (res) => resolve(res.serverId),
      fail: (error) => {
        console.error('上传音频至微信服务器失败（uploadVoice）');
        reject(error);
      },
    });
  });
}
