import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import userModule from "./modules/user";
import wxModule from "./modules/wx";
import equipmentModule from "./modules/equipment";
import loginModule from "./modules/login";
import loadingModule from "./modules/loading";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user: userModule,
    wx: wxModule,
    equipment: equipmentModule,
    login: loginModule,
    loading: loadingModule,
  },
  getters,
});

export default store;
