import router from '@/router';
import store from '@/store';
import wechatAuth from '@/plugins/auth';
import wechatConfig from './plugins/config';
// import { fetchWechatUserInfo } from '@/services/wx';
// import { fetchAccount } from '@/services/account';
import { fetchMenus } from '@/services/menu';
import { checkIsCanReadEquipment } from '@/services/equipment';
// import {
//   getEquipmentId,
//   getPageTitle,
//   isFromVersion2,
//   extractRawEquipmentId,
//   isEquipmentPage,
// } from '@/utils/index.js';
import { getPageTitle, extractRawEquipmentId } from '@/utils/index.js';
// import { Toast } from 'vant';

wechatAuth.setAppId(process.env.VUE_APP_WECHAT_APPID);

// 初始化app端菜单
export const initMenus = async () => {
  try {
    const { data, code } = await fetchMenus();
    if (code === 0) {
      store.dispatch('setRoutesMapAndGlobalAuthorities', data);
    }
  } catch (error) {
    console.error(error);
  }
};

// 检查是否有设备的查看权限
export const checkEquipmentAuthority = async (equipmentId) => {
  try {
    const res = await checkIsCanReadEquipment(
      equipmentId,
      !store.getters.globalAuthorities.includes('ALL'),
    );
    return res.code === 0;
  } catch (error) {
    console.error(error);
    return false;
  }
};

// 初始获取微信用户信息以及云智臻账户信息
const initWechat = async () => {
  const rawEquipmentId = extractRawEquipmentId();
  store.commit('SET_EQUIPMENT_ID', rawEquipmentId);
  await wechatConfig.config();
  // try {
  //   const res = await fetchWechatUserInfo(code);
  //   if (res.code === 0) {
  //     store.commit('SET_WECHAT_USER_INFO', res.data);
  //     await wechatConfig.config();
  //     if (!isEquipmentPage()) {
  //       Toast({
  //         message: '页面过期，请重新扫描设备进入页面',
  //         duration: 3000,
  //       });
  //       setTimeout(() => {
  //         if (window.wx) {
  //           window.wx.closeWindow();
  //         }
  //         window.close();
  //       }, 3000);
  //       return;
  //     }
  //     const accountRes = await fetchAccount(res.data.openid);
  //     const rawEquipmentId = extractRawEquipmentId();
  //     const isFromV2 = isFromVersion2();
  //     store.commit('SET_IS_FROM_V2', isFromV2);
  //     if (accountRes.code === 0) {
  //       await initMenus();
  //       const equipmentId = isFromV2
  //         ? await getEquipmentId(rawEquipmentId)
  //         : rawEquipmentId;
  //       if (!equipmentId) {
  //         return;
  //       }
  //       store.commit('SET_EQUIPMENT_ID', equipmentId);
  //       const checkRes = await checkEquipmentAuthority(equipmentId);
  //       if (!checkRes) {
  //         router.replace('/no-access');
  //       } else {
  //         store.dispatch('setUserInfo', accountRes.data);
  //       }
  //     } else {
  //       store.commit('SET_EQUIPMENT_ID', rawEquipmentId);
  //       router.replace('/prebind');
  //     }
  //   }
  // } catch (error) {
  //   console.error(error);
  // }
};

const initWechatAuth = async () => {
  const isHasCode = wechatAuth.isHasCode();
  if (isHasCode) {
    try {
      wechatAuth.returnFromWechat(window.location.href);
      initWechat(wechatAuth.code);
    } catch (error) {
      console.error(error);
    }
  } else {
    wechatAuth.redirectUri = wechatAuth.processUrl();
    window.location.href = wechatAuth.authUrl;
  }
};

router.beforeEach(async (to, from, next) => {
  document.title = getPageTitle(to.meta.title);
  if (store.getters.openId) {
    return next();
  }

  await initWechatAuth();

  return next();
});

router.afterEach(() => {
  if (window.wx) {
    window.wx.hideAllNonBaseMenuItem();
  }
});
