import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/prebind',
    },
    {
      path: '/equipment/:equipmentId',
      component: () =>
        import(
          /* webpackChunkName: 'Equipment-Detail' */ '../pages/equipment/index.vue'
        ),
      meta: {
        title: '设备详情',
      },
    },
    {
      path: '/no-access',
      component: () =>
        import(
          /* webpackChunkName: 'No-Access' */ '../pages/equipment/no-access/index.vue'
        ),
      meta: {
        title: '无权访问',
      },
    },
    {
      path: '/Center/scanRelease/:equipmentId/:departmentId/:hospitalId',
      component: () =>
        import(
          /* webpackChunkName: 'Equipment-Detail' */ '../pages/equipment/index.vue'
        ),
      meta: {
        title: '设备详情',
      },
    },
    {
      path: '/bind',
      component: () =>
        import(/* webpackChunkName: 'Bind' */ '../pages/bind/index.vue'),
      meta: {
        title: '绑定微信账号',
      },
    },
    {
      path: '/prebind',
      component: () =>
        import(/* webpackChunkName: 'PreBind' */ '../pages/bind/pre-bind.vue'),
      meta: {
        title: '云智臻',
      },
    },
    {
      path: '/repair',
      component: () =>
        import(/* webpackChunkName: 'Repair' */ '../pages/repair/index.vue'),
      children: [
        {
          path: 'result',
          component: () =>
            import(
              /* webpackChunkName: 'Repair-Result' */ '../pages/repair/result/index.vue'
            ),
          meta: {
            title: '操作结果',
          },
        },
      ],
    },
    {
      path: '/repair/:action',
      component: () =>
        import(
          /* webpackChunkName: 'Repair-Edit' */ '../pages/repair/edit.vue'
        ),
      meta: {
        title: '发起报修',
      },
    },
    {
      path: '/timeline/:taskId',
      component: () =>
        import(
          /* webpackChunkName: 'TaskTimeline' */ '../pages/timeline/index.vue'
        ),
      meta: {
        title: '操作历史',
      },
    },
    {
      path: '/selector',
      component: () =>
        import(
          /* webpackChunkName: 'TaskTimeline' */ '../components/selector/index.vue'
        ),
      meta: {
        title: '选择列表',
      },
    },
  ],
});

export default router;
