import Vue from 'vue';
import {
  Button,
  Tabs,
  Tab,
  Cell,
  Tag,
  CellGroup,
  Image as VanImage,
  Toast,
  Loading,
  Swipe,
  SwipeItem,
  Row,
  Col,
  Form,
  Field,
  Dialog,
  Step,
  Steps,
  Empty,
  Switch,
  SwipeCell,
  Popup,
  DatetimePicker,
  Picker,
} from 'vant';
import Icon from '@/components/icon/index.vue';
import 'vant/lib/index.css';

const installComponent = () => {
  Vue.component('iconfont', Icon);
  Vue.use(Button);
  Vue.use(Tabs);
  Vue.use(Tab);
  Vue.use(Cell);
  Vue.use(CellGroup);
  Vue.use(VanImage);
  Vue.use(Loading);
  Vue.use(Swipe);
  Vue.use(Switch);
  Vue.use(SwipeItem);
  Vue.use(Row);
  Vue.use(Col);
  Vue.use(Form);
  Vue.use(Field);
  Vue.use(Tag);
  Vue.use(Step);
  Vue.use(Steps);
  Vue.use(Empty);
  Vue.use(SwipeCell);
  Vue.use(Popup);
  Vue.use(Picker);
  Vue.use(DatetimePicker);
  Vue.use(Toast);
  Vue.use(Dialog);
};

export default installComponent;
