const mutations = {
  SET_EQUIPMENT_INFO(state, info) {
    state.equipmentId = info.id;
    state.equipmentName = info.name;
    state.equipmentManufacturerName = info.manufacturerName;
    state.equipmentModelName = info.modelName;
    state.equipmentSn = info.sn;
    state.equipmentNo = info.equipmentNo;
    state.equipmentDepartmentId = info.departmentId;
    state.equipmentDepartmentName = info.departmentName;
  },
  SET_EQUIPMENT_ID(state, id) {
    state.equipmentId = id;
  },
  SET_IS_FROM_V2(state, isFromV2) {
    state.isFromV2 = isFromV2;
  },
};

export default {
  state() {
    return {
      equipmentId: undefined,
      equipmentName: undefined,
      equipmentManufacturerName: undefined,
      equipmentModelName: undefined,
      equipmentSn: undefined,
      equipmentNo: undefined,
      equipmentDepartmentId: undefined,
      equipmentDepartmentName: undefined,
      isFromV2: false, // 是否为二版二维码扫描出来的设备
    };
  },
  mutations,
};
