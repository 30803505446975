const mutations = {
  SET_WECHAT_USER_INFO(state, data) {
    state.openId = data.openid;
    state.headimgurl = data.headimgurl;
    state.nickname = data.nickname;
  },
};

export default {
  state() {
    return {
      openId: undefined,
      headimgurl: undefined,
      nickname: undefined,
    };
  },
  mutations,
};
