import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { fetchAccount } from '@/services/account';
import { Toast } from 'vant';

const reLogin = async () => {
  try {
    const { code } = await fetchAccount(store.getters.openId);
    if (code === 0) {
      router.go();
    }
  } catch (error) {
    console.error(error);
  }
};

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    // if (store.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    // config.headers['X-Token'] = 'fdsfdsf';
    // }
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  },
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 0) {
      Toast.fail({
        message: res.msg || '网络异常',
        duration: 5000,
      });
    }
    return res;
  },
  (error) => {
    const status = error.response.status;
    let message = '网络异常';

    switch (status) {
      case 401:
        console.log('-------');
        message = '登录过期';
        reLogin();
        break;
      default:
        message = error.response.data.msg || '网络异常';
        break;
    }
    Toast.fail({
      message: message,
      duration: 5000,
    });

    return Promise.reject(error);
  },
);

export default service;
