import { fetchWechatJsApiTicket } from '@/services/wx';

class WechatConfig {
  async config() {
    const url = encodeURIComponent(location.href.split('#')[0]);
    const res = await fetchWechatJsApiTicket(url);
    await this.emitConfig(res);
  }

  emitConfig(res) {
    return new Promise((resolve, reject) => {
      if (window.wx) {
        if (res.code === 0) {
          window.wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: process.env.VUE_APP_WECHAT_APPID, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.noncestr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: [
              // 图像接口
              'chooseImage', // 拍照或从手机相册中选图接口
              'previewImage', // 预览图片接口
              'uploadImage', // 上传图片接口
              'downloadImage', // 下载图片接口
              'getLocalImgData', // 获取本地图片接口
              // 语音接口
              'startRecord', // 开始录音接口
              'stopRecord', // 停止录音接口
              'onVoiceRecordEnd', // 监听录音自动停止接口
              'playVoice', // 播放语音接口
              'pauseVoice', // 暂停播放接口
              'stopVoice', // 停止播放接口
              'onVoicePlayEnd', // 监听语音播放完毕接口
              'uploadVoice', // 上传语音接口
              'downloadVoice', // 下载语音接口
              // 界面接口
              'closeWindow',
              'hideMenuItems',
              'showMenuItems',
              'hideAllNonBaseMenuItem',
              'showAllNonBaseMenuItem',
            ], // 必填，需要使用的JS接口列表
          });
        } else {
          return reject(new Error('JsApiTicket获取失败'));
        }
        window.wx.ready(() => {
          // 隐藏所有非基础按钮接口
          window.wx.hideAllNonBaseMenuItem();
          return resolve();
        });
        window.wx.error((res) => {
          console.log('微信config信息验证失败');
          console.log('wx error: ', res);
          return reject(res);
        });
      }
    });
  }

  ready(readyHandler) {
    console.log(readyHandler);
  }
}

const wechatConfig = new WechatConfig();

export default wechatConfig;
