const actions = {
  setUserInfo({ commit }, userInfo) {
    const employee = userInfo.employee ?? {};
    const org = userInfo.org ?? {};
    const crs = userInfo.crs ?? [];
    const user = userInfo.user ?? {};
    commit('SET_USERINFO', {
      customers: crs,
      employeeName: employee.name,
      employeeId: employee.id,
      employeePhone: employee.phone,
      userId: user.id,
      username: user.username,
      orgId: org.id,
      orgName: org.name,
    });
  },
  setRoutesMapAndGlobalAuthorities({ commit }, menus) {
    commit('SET_GLOBAL_AUTHORITIES', menus);
    commit('SET_ROUTES_MAP', menus);
  },
};

const mutations = {
  SET_USERINFO(state, userInfo) {
    state.customers = userInfo.customers;
    state.employeeId = userInfo.employeeId;
    state.employeePhone = userInfo.employeePhone;
    state.employeeName = userInfo.employeeName;
    state.userId = userInfo.userId;
    state.username = userInfo.username;
    state.orgId = userInfo.orgId;
    state.orgName = userInfo.orgName;
  },
  SET_GLOBAL_AUTHORITIES(state, menus) {
    const globalAuthorities = [];
    menus.forEach((menu) => {
      if (menu.flag === 'ALL') {
        globalAuthorities.push('ALL');
      }
    });
    state.globalAuthorities = globalAuthorities;
  },
  SET_ROUTES_MAP(state, menus) {
    const routesMap = {};
    menus.forEach(function walk(item) {
      const hasChildren = item.children !== null && item.children.length > 0;
      if (item.type !== 1) {
        return;
      }
      if (item.content) {
        routesMap[item.content] = {
          ...item,
          authorities: hasChildren
            ? item.children
                .filter((child) => child.type === 2)
                .map((child) => child.flag)
            : [],
        };
      }
      if (hasChildren) {
        item.children.forEach(walk);
      }
    });
    state.routesMap = routesMap;
  },
};

export default {
  state() {
    return {
      customers: [],
      employeeId: undefined,
      employeeName: undefined,
      employeePhone: undefined,
      userId: undefined,
      username: undefined,
      orgId: undefined,
      orgName: undefined,
      globalAuthorities: [],
      routesMap: {},
    };
  },
  mutations,
  actions,
};
